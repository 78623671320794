@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  html {
    @apply scroll-smooth;
  }
}


   #container {
       width: 100%;
       height: 100%;
     
       /* background: rgb(10 10 10 ); */
       --gap: 5em;
       --line: 1px;
       --color: rgba(255, 255, 255, 0.2);
     
       background-image: linear-gradient(
           -90deg,
           transparent calc(var(--gap) - var(--line)),
           var(--color) calc(var(--gap) - var(--line) + 1px),
           var(--color) var(--gap)
         ),
         linear-gradient(
           0deg,
           transparent calc(var(--gap) - var(--line)),
           var(--color) calc(var(--gap) - var(--line) + 1px),
           var(--color) var(--gap)
         );
       background-size: var(--gap) var(--gap);
     }
     