.container {
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 20vw;
  margin-top: 300px;
  margin-bottom: 300px;
}

.body{
  /* font-size: 5vw; */
}

.lineMask{
  overflow: hidden;
}

.lineMask p{
  margin: 0px;
  /* font-weight: 700; */
}

.gallery{
  height: 175vh;
  /* background: rgb(45, 45, 45); */
  position: relative;
  display: flex;
  gap: 2vw;
  padding: 2vw;
  box-sizing: border-box;
  overflow: hidden;
  .column{
      position: relative;
      height: 100%;
      width: 25%;
      min-width: 25%;
      /* min-width: 50%; */
      display: flex;
      flex-direction: column;
      gap: 2vw;
      .imageContainer{
          height: 100%;
          width: 100%;
          position: relative;
          border-radius: 1vw;
          overflow: hidden;
          img{
              object-fit: cover;
          }
      }
      &:nth-of-type(1){
          top: -45%;
      }
      &:nth-of-type(2){
          top: -95%;
      }
      &:nth-of-type(3){
          top: -45%;
      }
      &:nth-of-type(4){
          top: -75%;
      }
  }
}
.spacer{
  /* height: 100vh; */
}

/* @media screen and (max-width: 480px) {
  .gallery {
    .column{
      min-width: 50%;
  }
  }
} */
